<template>
    <div class="panel-style">
        <el-form :inline="true" :model="searchDto" size="mini">
            <el-form-item label="归属机构">
                <doart-select-tree :width="180" :data="orgData" clearable @change="handleSelectOrgChangeEvent"/>
            </el-form-item>
            <el-form-item label="角色" prop="roleId">
                <el-select v-model="searchDto.roleId" placeholder="请选择用户角色" clearable>
                    <el-option v-for="item in roleData" :key="item.id" :label="item.roleName" :value="item.id"/>
                </el-select>
            </el-form-item>
            <el-form-item label="登录名" prop="loginName">
                <el-input v-model="searchDto.loginName" placeholder="登录名"/>
            </el-form-item>
            <el-form-item label="用户名" prop="userName">
                <el-input v-model="searchDto.userName" placeholder="用户名"/>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
                <el-input v-model="searchDto.email" placeholder="邮箱"/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySysUserInfoList">查询</el-button>
                <el-button type="primary" @click="openCreateSysUserInfoPage">添加</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" border size="small" stripe max-height="550px"
            :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
            <el-table-column min-width="40px" prop="orgName" label="归属机构"/>
            <el-table-column width="140px" prop="roleName" label="角色"/>
            <el-table-column width="70px" prop="loginName" label="用户头像" align="center">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: center">
                        <div style="width: 30px;height:30px;">
                            <img v-if="scope.row.headSculpture===''" src="../../../assets/image/sys/user.png"
                                style="width: 30px;height:30px;border-radius: 50%;" alt=""/>
                            <img v-else :src="scope.row.headSculpture" style="width: 30px;height:30px;border-radius: 50%;" alt=""/>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="60px" prop="loginName" label="登录名"/>
            <el-table-column min-width="60px" prop="userName" label="用户名"/>
            <el-table-column width="50px" prop="status" label="状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.status" style="color: #F56C6C">禁用</div>
                    <div v-else style="color: #67C23A;">启用</div>
                </template>
            </el-table-column>
            <el-table-column prop="email" label="邮箱"/>
            <el-table-column width="70px" prop="temperatureUnitName" label="温度单位" align="center"/>
            <el-table-column width="100px" label="操作">
                <template slot-scope="scope">
                    <div class="option-btn-div">
                        <el-tooltip v-delTabIndex effect="dark" content="编辑" placement="top">
                            <em class="el-icon-edit option-btn-normal" @click="openEditSysUserInfoPage(scope.row.id)"/>
                        </el-tooltip>
                        <el-tooltip v-delTabIndex effect="dark" content="删除" placement="top">
                            <em class="el-icon-delete option-btn-normal" style="color: #F56C6C"
                                @click="removeSysUserInfo(scope.row.id)"/>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
                :current-page.sync="searchDto.pageNum"
                :page-sizes="[10, 50, 100]"
                :page-size="searchDto.pageSize"
                @size-change="handleSizeChangeEvent"
                @current-change="handleCurrentChangeEvent"/>
        </div>
        <sys-user-detail ref="sysUserDetail" @querySysUserInfoList="querySysUserInfoList"></sys-user-detail>
    </div>
</template>
<script>
import global from '@/components/Global';
import message from "@/utils/message";
import DoartSelectTree from '@/components/doart-select-tree/doart-select-tree';
import SysUserDetail from '@/views/sys/user/sys-user-detail';
import {querySysUserInfoList, removeSysUserInfo} from '@/api/sys/userApi';
import {queryCurrentUserOrgTree} from "@/api/sys/org/sysOrgApi";
import {queryCurrentUserAvailableRoleInfo, queryAvailableRoleInfoByOrgId} from '@/api/sys/roleApi';

export default {

    name: "sys-user-list",
    components: {

        DoartSelectTree,
        SysUserDetail
    },
    data() {

        return {

            orgData: [],
            roleData: [],
            searchDto: {

                orgId: '',
                loginName: '',
                userName: '',
                roleId: '',
                email: '',
                pageNum: 1,
                pageSize: 10
            },
            tableData: [],
            tableTotal: 0,
            listLoading: false
        };
    },
    mounted() {

        this.queryOrgInfoList();
        this.queryRoleInfoList();
        this.querySysUserInfoList();
    },
    methods: {

        queryOrgInfoList() {

            queryCurrentUserOrgTree().then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.orgData = [res.data];
                }
            });
        },
        queryRoleInfoList() {

            queryCurrentUserAvailableRoleInfo().then(res => {

                if (res.code === global.response_status_success_obj) {

                    this.roleData = res.data;
                }
            });
        },
        querySysUserInfoList() {

            this.listLoading = true;
            querySysUserInfoList(this.searchDto).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableTotal = res.data.total;
                    this.tableData = res.data.result;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
                this.listLoading = false;
            });
        },
        openCreateSysUserInfoPage() {

            this.$refs.sysUserDetail.openCreateSysUserInfoPage();
        },
        openEditSysUserInfoPage(id) {

            this.$refs.sysUserDetail.openEditSysUserInfoPage(id);
        },
        removeSysUserInfo(id) {

            this.$confirm('是否需要删除？', '提示', {type: 'warning'}).then(() => {

                let params = {

                    id: id
                };
                removeSysUserInfo(params).then(res => {

                    if (res.code === global.response_status_success_obj) {

                        message.success('用户删除成功');
                        this.querySysUserInfoList();
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => {

                    message.error('请求失败,请稍后再试');
                    console.error(err);
                });
            });
        },
        handleSelectOrgChangeEvent(orgId) {

            this.searchDto.orgId = orgId;
            let params = {

                id: orgId
            }
            if (orgId !== '' && orgId !== null) {

                queryAvailableRoleInfoByOrgId(params).then(res => {

                    if (res.code === global.response_status_success_obj) {

                        this.roleData = res.data;
                        this.searchDto.roleId = '';
                    }
                });
            }
        },
        handleSizeChangeEvent(val) {

            this.searchDto.pageSize = val;
            this.querySysUserInfoList();
        },
        handleCurrentChangeEvent(val) {

            this.searchDto.pageNum = val;
            this.querySysUserInfoList();
        }
    }
}
</script>
<style scoped lang="scss">
.pagination {

    margin-top: 15px;
    text-align: right;
}
.option-btn-div {

    display: flex;
    justify-content: space-around
}
.option-btn-normal {

    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
}
</style>